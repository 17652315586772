import React from "react";
import { useInView } from "react-intersection-observer";
import LazyLoad from "./LazyLoad";

const Picture = ({ img, full, style = {} }) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  return (
    <div ref={ref}>
      <LazyLoad img={img} style={style} full={full} isVisible={true} />
    </div>
  );
};

export default Picture;
