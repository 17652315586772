const externalTools = {
  eventNavigation: (pageName, pageTitle) => {
    const dL = process.env.NODE_ENV === "production" ? window?.dataLayer : [];
    if (window.dataLayer) {
      dL.push({
        event: "navigation",
        page: pageName,
        title: pageTitle,
      });
    }

    // void 0 === window._axcb && (window._axcb = []);
    // var pageName = pageName;
    // window._axcb.push(function (axeptio) {
    //   axeptio.on("cookies:complete", function (choices) {
    //     if (window.dataLayer) {
    //       dataLayer?.push({
    //         event: "navigation",
    //         page: pageName,
    //         title: pageTitle,
    //       });
    //     }
    //   });
    // });
  },
};

export default externalTools;
