import { useEffect, useRef, useState } from "react";
import Link from "next/link";

let fullImageLoaded = false;
let platform = "";
let initialBackgroundPositionY = null;

const HeroSection = ({ scrollElement, name, children }) => {
  const placeholderRef = useRef(null);
  const sectionRef = useRef(null);
  const blurRef = useRef(null);

  const bgImages = {
    desktop: {
      large: `/front/hero/large/bg_${name}.webp`,
      small: `/front/hero/large/thumb/bg_${name}_sm.webp`,
    },
    tablet: {
      large: `/front/hero/medium/bg_${name}.webp`,
      small: `/front/hero/large/thumb/bg_${name}_sm.webp`,
    },

    mobile: {
      large: `/front/hero/small/bg_m_${name}.webp`,
      small: `/front/hero/small/thumb/bg_m_${name}_sm.webp`,
    },
  };

  const loadLargeImage = (bgImage) => {
    var section = sectionRef.current;
    var blur = blurRef.current;
    const placeholder = placeholderRef.current;
    placeholder.html = "";
    blur.classList.remove("loaded");
    blur.classList.remove("finished");
    // Load small image and show it
    var img = new Image();
    img.classList.add("img-small");
    // placeholder.appendChild(img);
    img.src = bgImage.small + "";
    img.onload = () => {
      if (!fullImageLoaded) {
        section.style.backgroundImage = `url(${bgImage.small})`;
      }
      document
        .querySelector(".divide-section .sub-header")
        .classList.add("loaded");
      blur.classList.add("loaded");
      console.log("thumbnail loaded", bgImage.small);
    };

    // Load large image
    var imgLarge = new Image();
    imgLarge.src = bgImage.large + "";
    imgLarge.onload = () => {
      blur.classList.add("finished");
      section.style.backgroundImage = `url(${bgImage.large})`;
      console.log("full image loaded", bgImage.large);
      fullImageLoaded = true;
    };
  };

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) {
      resetBackgroundPosition();
    }

    let targetPlatform = screenWidth >= 768 ? "desktop" : "mobile";
    if (platform === targetPlatform) {
      return;
    }
    if (screenWidth > 768) {
      if (screenWidth > 1100) {
        platform = "desktop";
        loadLargeImage(bgImages.desktop);
      } else {
        platform = "tablet";
        loadLargeImage(bgImages.tablet);
      }
    } else {
      platform = "mobile";
      loadLargeImage(bgImages.mobile);
    }
  };

  const resetBackgroundPosition = () => {
    sectionRef.current.style.backgroundPositionY = "";
    setTimeout(() => {
      initialBackgroundPositionY = parseFloat(
        window.getComputedStyle(sectionRef.current).backgroundPositionY
      );
      handleScroll();
    }, 500);
  };

  const handleScroll = () => {
    //disable if window width less than 1100px
    if (window.innerWidth < 1100) {
      return;
    }
    const { current } = sectionRef;
    const scrollSource = scrollElement
      ? document.querySelector(scrollElement)
      : window;
    const isWindow = scrollSource === window;
    if (current) {
      if (window.scrollY < window.innerHeight) {
        // Get the scroll offset depending on the source
        const offset = isWindow ? window.scrollY : scrollSource.scrollTop;
        // Calculate new background position Y based on initial offset and scroll
        const newBackgroundPositionY =
          initialBackgroundPositionY + offset * 0.13;
        // Apply the new Y offset
        current.style.backgroundPositionY = `${newBackgroundPositionY}px`;
      }
    }
  };

  useEffect(() => {
    // Initial load
    platform = "";
    fullImageLoaded = false;
    // Debounce resize function
    let resizeTimer;
    const debouncedResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        handleResize();
      }, 500);
    };
    // Debounce scroll function

    window.addEventListener("resize", debouncedResize);

    // Determine the scroll source: specific element or window
    const scrollSource = scrollElement
      ? document.querySelector(scrollElement)
      : window;
    const isWindow = scrollSource === window;

    // Retrieve the initial Y offset
    initialBackgroundPositionY = parseFloat(
      window.getComputedStyle(sectionRef.current).backgroundPositionY
    );

    // const handleScroll = () => {
    //   const { current } = sectionRef;
    //   if (current) {
    //     // Get the scroll offset depending on the source
    //     const offset = isWindow ? window.scrollY : scrollSource.scrollTop;
    //     // Calculate new background position Y based on initial offset and scroll
    //     const newBackgroundPositionY =
    //       initialBackgroundPositionY + offset * 0.1;
    //     // Apply the new Y offset
    //     current.style.backgroundPositionY = `${newBackgroundPositionY}px`;
    //   }
    // };
    setTimeout(() => {
      handleResize();
      handleScroll();
    }, 200);

    // Attach the scroll event listener
    scrollSource.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", debouncedResize);
      scrollSource.removeEventListener("scroll", handleScroll);
    };
  }, []); // Ensure effect runs again if scrollElement changes

  return (
    <>
      <div ref={sectionRef} className={`divide-section hero-${name}`}>
        <div ref={placeholderRef} className="placeholder">
          <div ref={blurRef} className="blur-filter-img"></div>
        </div>

        {children}
      </div>
      {/* <div className="wave-container"></div> */}
    </>
  );
};

export default HeroSection;
