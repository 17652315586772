import React, { useEffect, useState } from "react";

const LazyLoad = ({ isVisible, full, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [src, setSrc] = useState(
    `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${props.img.width} ${props.img.height}"%3E%3C/svg%3E`
  );

  useEffect(() => {
    if (isVisible) {
      loadImage();
    }
  }, [isVisible]);
  const loadImage = () => {
    setSrc(props.img.url);
  };

  const imageLoaded = () => {
    if (src == props.img.url) {
      setLoaded(true);
    }
  };

  const { url, alt } = props.img;

  return (
    // <motion.div
    //   initial="out"
    //   animate={loaded ? "in" : "out"}
    //   exit="out"
    //   variants={anim.fadeInAnim}
    //   transition={anim.fadeInTrans}
    // >
    <img
      onLoad={imageLoaded}
      style={{ borderRadius: full ? 0 : 30, ...props.style }}
      className={props.img.className || "img-fluid  article-image"}
      src={src}
      data-src={url}
      alt={alt}
      loading="lazy"
    />
    // </motion.div>
  );
};
export default LazyLoad;
