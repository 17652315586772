import React from "react";
import Picture from "../common/Picture";

const ArticleItemLeft = ({
  title,
  numberTitle,
  text,
  subText,
  icon,
  iconWidth,
  iconHeight,
  subIcon,
  subIconPath,
  subStyle = {},
  titleStyle = {},
  subTextClassName,
  alt = "illustration",
  titleClassname = "",
  pictureContainerClassName = "",
  containerClassName = "",
  image = "",
  anchor,
  style = {},
}) => {
  return (
    <section
      className={`row article-section mx-auto ${containerClassName}`}
      id={anchor}
      style={{ ...style, alignItems: "center" }}
    >
      <div className="col-12 col-md-6 p-0 ">
        <div className="article-item article-left">
          <header>
            {numberTitle && numberTitle}
            <h2 style={titleStyle} className={`${titleClassname} h3`}>
              {title}
            </h2>
          </header>
          <p className="mb-0 article-description">{text}</p>
          {subIcon && (
            <div className=" d-center justify-content-between article-sub-section">
              <div className="article-icon pr-2">
                <img
                  loading="lazy"
                  alt={alt}
                  src={subIconPath + subIcon}
                  className="img-fluid"
                />
              </div>
              <div
                className={"article-sub-text " + subTextClassName}
                style={subStyle}
              >
                <div className="mb-0">{subText}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`col-12 col-md-6 mt-0 mt-md-0 p-0 pl-md-5  text-center text-md-left ${pictureContainerClassName}`}
      >
        <Picture
          img={{
            url: image,
            width: iconWidth,
            height: iconHeight,
            alt: alt,
          }}
        />
        {/* <img
          loading="lazy"
          alt={alt}
          style={{ borderRadius: 10 }}
          className="img-fluid  article-image"
          src={"/images/home/images/" + icon}
        /> */}
      </div>
    </section>
  );
};

export default ArticleItemLeft;
