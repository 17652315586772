import React from "react";
import Picture from "../common/Picture";

const ArticleItemRight = ({
  numberTitle,
  title,
  text,
  subText,
  icon,
  iconWidth,
  iconHeight,
  subIcon,
  subIconPath,
  subStyle = {},
  titleStyle = {},
  titleClassname = "",
  alt = "illustration",
  pictureContainerClassName = "",
  containerClassName = "",
  image = "",
  style = {},
  anchor,
}) => {
  return (
    <section
      className={`row article-section mx-auto ${containerClassName}`}
      id={anchor}
      style={{ ...style, alignItems: "center" }}
    >
      <div
        className={`col-12 col-md-6 p-0 pr-md-5 order-1 order-md-0 mt-0 mt-md-0  ${pictureContainerClassName}`}
      >
        <Picture
          img={{
            url: image,
            width: iconWidth,
            height: iconHeight,
            alt: alt,
          }}
        />
      </div>
      <div className={`col-12 col-md-6 p-0 order-0 order-md-1`}>
        <div className="article-item article-left">
          <header>
            {numberTitle}
            <h2 className={`${titleClassname} h3`} style={titleStyle}>
              {title}
            </h2>
          </header>
          <p className="mb-0 article-description">{text}</p>
          {subIcon && (
            <div className=" d-center justify-content-between  article-sub-section">
              <div className="article-icon pr-2">
                <img
                  alt={alt}
                  loading="lazy"
                  src={subIconPath + subIcon}
                  className="img-fluid"
                />
              </div>
              <div className="article-sub-text" style={subStyle}>
                <div className="mb-0">{subText}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ArticleItemRight;
