import Link from "next/link";
import React from "react";

const TitleSection = ({
  title,
  description,
  cta,
  ctaHref = "",
  content = null,
}) => {
  return (
    <section className="title-section w-100">
      <div className="sub-header">
        <div>
          <h1 className="main-h1">{title}</h1>
          <p className="title-description">{description}</p>
          {cta !== "" && (
            <Link href={ctaHref || "/assurer-mon-entreprise"}>
              <a className="hero-cta main-cta in-header btn btn-secondary d-inline-flex justify-content-center animated align-items-center align-self-center tracking-gtm-FormView">
                <span>{cta}</span>
              </a>
            </Link>
          )}
        </div>
        {cta !== "" && (
          <Link href={ctaHref || "/assurer-mon-entreprise"}>
            <a className="hero-cta main-cta out-header btn btn-secondary d-inline-flex justify-content-center animated align-items-center align-self-center tracking-gtm-FormView">
              <span>{cta}</span>
            </a>
          </Link>
        )}
        {content}
      </div>
    </section>
  );
};

export default TitleSection;
